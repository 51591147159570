import { gte, isEmpty, isNumber, isString } from 'lodash';
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MdClear } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';

interface Props {
  name: string;
  hasNoSeparator?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeHolder?: boolean;
  autoComplete?: string;
  maxLength?: number;
  label?: string;
  error?: { message?: string };
  formatter?: (value: string | number | readonly string[]) => string | ReactNode;
  wrapperClassName?: string;
  labelClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const FormNumberInput = ({
  name,
  disabled,
  hasNoSeparator,
  required,
  placeHolder,
  autoComplete,
  maxLength,
  label,
  error,
  formatter,
  wrapperClassName,
  labelClassName,
  onChange,
  onBlur,
}: Props) => {
  const form = useFormContext();
  const mainValueRegister = form.register(name);
  const mainValueWatch = useWatch({ control: form.control, name });
  const [subValue, setSubValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputWrapperElement = document.getElementById(`form-number-input-wrapper-${name}`);
  const mainInputElement = document.getElementById(`${name}-main-form-number-input`);
  const subInputElement = document.getElementById(`${name}-sub-form-number-input`);

  const handleOnClickWrapper = () => {
    if (subInputElement) {
      subInputElement.focus();
    }
  };
  const handleOnFocusMainInput = () => {
    if (inputWrapperElement) {
      inputWrapperElement.click();
    }
  };
  const handleOnFocusSubInput = () => {
    setIsFocused(true);
  };
  const handleOnBlurSubInput = (event: FocusEvent<HTMLInputElement>) => {
    mainValueRegister.onBlur(event);
    onBlur?.(event);
    setIsFocused(false);
  };
  const handleOnChangeSubInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const pureValue = value.replaceAll(',', '');
    const cloneEvent = { ...event };
    if (mainInputElement) {
      cloneEvent.target = mainInputElement as EventTarget & HTMLInputElement;
      cloneEvent.target.value = pureValue;
    }
    mainValueRegister.onChange(cloneEvent);
    onChange?.(cloneEvent);
    setSubValue(value);
  };
  const handleOnClearInput = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const changeEvent = new Event('change', { bubbles: true });
    mainInputElement?.dispatchEvent(changeEvent);
    if (changeEvent.target) {
      (changeEvent.target as EventTarget & HTMLInputElement).value = '';
    }
    form.setValue(name, undefined);
    onChange?.(changeEvent as any);
    setSubValue('');
  };
  const handleOnRenderFormattedValue = () => (formatter ? formatter(mainValueWatch) : null);

  useEffect(() => {
    setSubValue(
      isString(mainValueWatch)
        ? hasNoSeparator
          ? mainValueWatch
          : mainValueWatch.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : '',
    );
  }, [mainValueWatch]);

  return (
    <div className={`flex select-none flex-col space-y-[3px] ${disabled ? '' : 'cursor-text'}`}>
      <div className={disabled ? 'pointer-events-none' : ''} onClick={handleOnClickWrapper}>
        <fieldset
          id={`form-number-input-wrapper-${name}`}
          className={`group relative flex items-center rounded-[8px] border ${
            error ? 'border-alert' : isFocused ? 'border-primary' : 'border-stroke'
          } ${wrapperClassName ?? ''} ${disabled ? 'bg-disabled' : ''}`}
        >
          {isFocused || (!isFocused && !isEmpty(mainValueWatch)) ? (
            !placeHolder && (
              <legend className='pointer-events-none mx-[2px] px-[4px] leading-[0]'>
                <label className='text-[12px]'>
                  <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
                  {required && <span className='text-alert'>*</span>}
                </label>
              </legend>
            )
          ) : (
            <label className='pointer-events-none absolute top-1/2 -translate-y-1/2'>
              <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
              {required && <span className='text-alert'>*</span>}
            </label>
          )}
          <input
            {...mainValueRegister}
            id={`${name}-main-form-number-input`}
            type='text'
            tabIndex={disabled ? -1 : undefined}
            className='w-0 opacity-0'
            onFocus={handleOnFocusMainInput}
            onBlur={onBlur}
            onChange={onChange}
          />
          <NumericFormat
            id={`${name}-sub-form-number-input`}
            thousandSeparator={!hasNoSeparator}
            inputMode='numeric'
            maxLength={isNumber(maxLength) && gte(maxLength, 0) ? maxLength : 19}
            autoComplete={autoComplete}
            value={subValue}
            tabIndex={disabled ? -1 : undefined}
            className='w-full bg-transparent'
            onFocus={handleOnFocusSubInput}
            onBlur={handleOnBlurSubInput}
            onChange={handleOnChangeSubInput}
          />
          {!isEmpty(mainValueWatch) && (
            <button
              type='button'
              className='ml-[8px] hidden group-hover:flex'
              onClick={handleOnClearInput}
            >
              <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
            </button>
          )}
        </fieldset>
      </div>
      {error && (
        <div className='flex w-full py-[3px] px-[12px] '>
          <span className='text-[12px] leading-[15px] text-alert'>{error?.message}</span>
        </div>
      )}
      {!isEmpty(mainValueWatch) && formatter && <span>{handleOnRenderFormattedValue()}</span>}
    </div>
  );
};

export default FormNumberInput;
