import { gql } from '@apollo/client';
import { Boolean, String } from '../queries/get-real-estates';

export enum SendVerificationCodeType {
  signUp = 'signUp',
  changePhone = 'changePhone',
  forgotPassword = 'forgotPassword',
}

export interface MutationOfString {
  payload?: String;
  success?: Boolean;
  msg?: String;
}

export interface VerifyOTPData {
  verifyOTP?: MutationOfString;
}
export interface VerifyOTPVariables {
  phone: String;
  type?: SendVerificationCodeType;
  OTP: String;
  identifier: String;
}

export const VERIFY_OTP = gql`
  mutation verifyOTP(
    $phone: String!
    $type: SendVerificationCodeType
    $OTP: String!
    $identifier: String!
  ) @api(name: users) {
    verifyOTP(phone: $phone, type: $type, OTP: $OTP, identifier: $identifier) {
      payload
      success
      msg
    }
  }
`;
