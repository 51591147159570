import { PHONE_NUMBER_REGEX } from 'globalConstants';
import { isEqual, toString } from 'lodash';
import * as yup from 'yup';

export interface BookingForm {
  paymentType?: string;
  customerName?: string;
  customerPhone?: string;
  idNumber?: string;
  paymentAmount?: string;
  currentUser?: string;
  currentUserPhone?: string;
}

export const bookingFormDefaultValues: BookingForm = {
  paymentType: undefined,
  customerName: undefined,
  customerPhone: undefined,
  idNumber: undefined,
  paymentAmount: undefined,
  currentUser: undefined,
  currentUserPhone: undefined,
};

export const bookingFormSchema = yup.object().shape({
  paymentType: yup.string().required('Vui lòng chọn loại thanh toán'),
  customerName: yup.string().required('Vui lòng nhập tên khách hàng'),
  customerPhone: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  idNumber: yup
    .string()
    .required('Vui lòng nhập số CMND/ CCCD')
    .test(
      'Length Test',
      'Vui lòng nhập CMND/CCCD hợp lệ',
      (val) => isEqual(toString(val).length, 9) || isEqual(toString(val).length, 12),
    ),
  paymentAmount: yup.string().required('Vui lòng nhập Số tiền đã thanh toán trước'),
});
