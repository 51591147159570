import { isEmpty, isEqual, isUndefined, size } from 'lodash';
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  MouseEvent,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MdClear } from 'react-icons/md';

interface Props {
  name: string;
  disabled?: boolean;
  required?: boolean;
  placeHolder?: boolean;
  type?: 'text' | 'password';
  autoComplete?: string;
  maxLength?: number;
  label?: string;
  error?: { message?: string };
  endIcon?: any;
  wrapperClassName?: string;
  labelClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const isBrowser = !isEqual(typeof window, 'undefined');

const FormTextInput = ({
  name,
  disabled,
  required,
  placeHolder,
  type,
  autoComplete,
  maxLength,
  label,
  error,
  endIcon,
  wrapperClassName,
  labelClassName,
  onChange,
  onBlur,
}: Props) => {
  const form = useFormContext();
  const valueRegister = form.register(name);
  const valueWatch = useWatch({ control: form.control, name });
  const [isFocused, setIsFocused] = useState(false);
  const inputElement = isBrowser ? document.getElementById(`${name}-form-text-input`) : null;

  const handleOnClickWrapper = () => {
    if (inputElement) {
      (inputElement as HTMLInputElement).focus();
    }
  };
  const handleOnFocusInput = () => {
    setIsFocused(true);
  };
  const handleOnBlurInput = (event: FocusEvent<HTMLInputElement>) => {
    valueRegister.onBlur(event);
    onBlur?.(event);
    setIsFocused(false);
  };
  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    valueRegister.onChange(event);
    onChange?.(event);
  };
  const handleOnClearInput = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (inputElement) {
      (inputElement as HTMLInputElement).value = '';
      inputElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
    const changeEvent = new Event('change', { bubbles: true });
    inputElement?.dispatchEvent(changeEvent);
    if (changeEvent.target) {
      (changeEvent.target as EventTarget & HTMLInputElement).value = '';
    }
    form.setValue(name, undefined);
    onChange?.(changeEvent as any);
  };

  return (
    <div className={`flex select-none flex-col space-y-[3px] ${disabled ? '' : 'cursor-text'}`}>
      <div className={disabled ? 'pointer-events-none' : ''} onClick={handleOnClickWrapper}>
        <fieldset
          id={`form-text-input-wrapper-${name}`}
          className={`group relative flex items-center rounded-[8px] border ${
            error ? 'border-alert' : isFocused ? 'border-primary' : 'border-stroke'
          } ${wrapperClassName ?? ''} ${disabled ? 'bg-disabled' : ''}`}
        >
          {isFocused || (!isFocused && !isEmpty(valueWatch)) ? (
            !placeHolder && (
              <legend className='pointer-events-none mx-[2px] px-[4px] leading-[0]'>
                <label className='text-[12px]'>
                  <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
                  {required && <span className='text-alert'>*</span>}
                </label>
              </legend>
            )
          ) : (
            <label className='pointer-events-none absolute top-1/2 -translate-y-1/2'>
              <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
              {required && <span className='text-alert'>*</span>}
            </label>
          )}
          <input
            {...valueRegister}
            id={`${name}-form-text-input`}
            type={type}
            autoComplete={autoComplete}
            maxLength={maxLength}
            tabIndex={disabled ? -1 : undefined}
            className='w-full bg-transparent'
            onFocus={handleOnFocusInput}
            onBlur={handleOnBlurInput}
            onChange={handleOnChangeInput}
          />
          {!isEmpty(valueWatch) && (
            <button
              type='button'
              className='ml-[8px] hidden group-hover:flex'
              onClick={handleOnClearInput}
            >
              <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
            </button>
          )}
          {endIcon && <div className='ml-[8px] flex items-center border-l pl-[8px]'>{endIcon}</div>}
        </fieldset>
      </div>
      {(!isUndefined(maxLength) || error) && (
        <div className='flex w-full justify-between py-[3px] px-[12px] text-[12px] leading-[15px]'>
          <span className='text-alert'>{error?.message}</span>
          {maxLength && (
            <span className=' text-right text-text2'>{`${size(valueWatch)}/${maxLength}`}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormTextInput;
