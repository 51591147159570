import { useConfigurationContext } from 'components/providers/configuration-provider';
import FormNumberInput from 'components/shared/form-number-input';
import FormRadio from 'components/shared/form-radio';
import FormSelect from 'components/shared/form-select';
import FormTextInput from 'components/shared/form-text-input';
import { PostAndUpdateRealEstateForm } from 'forms';
import {
  PROJECTS_LIST,
  Project,
  ProjectsListData,
  ProjectsListVariables,
} from 'graphql/main/queries/projects-list';
import { RealEstateTypeEnumType } from 'graphql/main/queries/slugery';
import {
  GET_LIST_DISTRICTS_OF_PROVINCE,
  GetListDistrictsOfProvinceData,
  GetListDistrictsOfProvinceVariables,
} from 'graphql/map/queries/get-list-districts-of-province';
import {
  GET_LIST_STREETS_OF_DISTRICT,
  GetListStreetsOfDistrictData,
  GetListStreetsOfDistrictVariables,
} from 'graphql/map/queries/get-list-streets-of-district';
import {
  GET_LIST_WARDS_OF_DISTRICT,
  GetListWardsOfDistrictData,
  GetListWardsOfDistrictVariables,
} from 'graphql/map/queries/get-list-wards-of-district';
import { useFlexQuery, useTranslation } from 'hooks';
import {
  filter,
  find,
  gt,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  range,
  round,
  size,
  toNumber,
  toString,
} from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { convertNumberToVietnameseCurrencyLongString } from 'utils';

export const hasProjectTypes = [
  RealEstateTypeEnumType.villa,
  RealEstateTypeEnumType.land,
  RealEstateTypeEnumType.apartment,
  RealEstateTypeEnumType.shophouse,
  RealEstateTypeEnumType.penthouse,
  RealEstateTypeEnumType.condotel,
];
export const hasBlockTypes = [
  RealEstateTypeEnumType.villa,
  RealEstateTypeEnumType.land,
  RealEstateTypeEnumType.apartment,
  RealEstateTypeEnumType.shophouse,
  RealEstateTypeEnumType.penthouse,
  RealEstateTypeEnumType.condotel,
];
export const hasDiffAreaLabelTypes = [
  RealEstateTypeEnumType.apartment,
  RealEstateTypeEnumType.penthouse,
  RealEstateTypeEnumType.condotel,
];
export const hasNoBedroomCountTypes = [
  RealEstateTypeEnumType.building,
  RealEstateTypeEnumType.land,
];
export const hasNoToiletCountTypes = [RealEstateTypeEnumType.land];
export const hasNoFloorCountTypes = [
  RealEstateTypeEnumType.apartment,
  RealEstateTypeEnumType.condotel,
];
export const requiredFloorCountTypes = [
  RealEstateTypeEnumType.townhouse,
  RealEstateTypeEnumType.villa,
  RealEstateTypeEnumType.building,
  RealEstateTypeEnumType.hotel,
  RealEstateTypeEnumType.warehouse,
  RealEstateTypeEnumType.shophouse,
  RealEstateTypeEnumType.penthouse,
];
export const hasOnFloorTypes = [
  RealEstateTypeEnumType.apartment,
  RealEstateTypeEnumType.penthouse,
  RealEstateTypeEnumType.condotel,
  RealEstateTypeEnumType.motel,
];
export const hasClearanceAreaTypes = [
  RealEstateTypeEnumType.apartment,
  RealEstateTypeEnumType.penthouse,
  RealEstateTypeEnumType.condotel,
];
export const requiredConstructionAreaTypes = [RealEstateTypeEnumType.motel];
export const hasBuildingTypeTypes = [RealEstateTypeEnumType.building];
export const hasWarehouseTypeTypes = [
  RealEstateTypeEnumType.warehouse,
  RealEstateTypeEnumType.land,
];
export const hasIndustryTradeTypes = [
  RealEstateTypeEnumType.townhouse,
  RealEstateTypeEnumType.shophouse,
];
export const hasHotelStarTypes = [RealEstateTypeEnumType.hotel];
export const hasHasMezzanineTypes = [RealEstateTypeEnumType.motel];

const DetailInformation = () => {
  const { major } = useConfigurationContext();
  const translation = useTranslation();
  const postAndUpdateRealEstateForm = useFormContext<PostAndUpdateRealEstateForm>();
  const { formState, control, setValue, clearErrors } = postAndUpdateRealEstateForm;
  const { errors } = formState;
  const {
    country: countryError,
    province: provinceError,
    district: districtError,
    alleyTurnCount: alleyTurnCountError,
    frontHomeAlleyWidth: frontHomeAlleyWidthError,
    project: projecError,
    block: blockError,
    width: widthError,
    length: lengthError,
    floorCount: floorCountError,
    constructionArea: constructionAreaError,
  } = errors;
  const provinceWatch = useWatch({ control, name: 'province' });
  const districtWatch = useWatch({ control, name: 'district' });
  const projectWatch = useWatch({ control, name: 'project' });
  const typeWatch = useWatch({ control, name: 'type' });
  const streetWatch = useWatch({ control, name: 'street' });
  const wardWatch = useWatch({ control, name: 'ward' });
  const houseNumberWatch = useWatch({ control, name: 'houseNumber' });
  const hasAlleyWatch = useWatch({ control, name: 'hasAlley' });
  const alleyTurnCountWatch = useWatch({ control, name: 'alleyTurnCount' });
  const frontHomeAlleyWidthWatch = useWatch({ control, name: 'frontHomeAlleyWidth' });
  const blockWatch = useWatch({ control, name: 'block' });
  const widthWatch = useWatch({ control, name: 'width' });
  const lengthWatch = useWatch({ control, name: 'length' });
  const rearWatch = useWatch({ control, name: 'rear' });
  const areaWatch = useWatch({ control, name: 'area' });
  const priceWatch = useWatch({ control, name: 'price' });
  const bedroomCountWatch = useWatch({ control, name: 'bedRoomCount' });
  const toiletCountWatch = useWatch({ control, name: 'toiletCount' });
  const floorCountWatch = useWatch({ control, name: 'floorCount' });
  const directionWatch = useWatch({ control, name: 'direction' });
  const onFloorWatch = useWatch({ control, name: 'onFloor' });
  const clearanceAreaWatch = useWatch({ control, name: 'clearanceArea' });
  const constructionAreaWatch = useWatch({ control, name: 'constructionArea' });
  const isNewConstructionWatch = useWatch({ control, name: 'isNewConstruction' });
  const buildingTypeWatch = useWatch({ control, name: 'buildingType' });
  const warehouseTypeWatch = useWatch({ control, name: 'warehouseType' });
  const industryTradeWatch = useWatch({ control, name: 'industryTrade' });
  const hotelStarWatch = useWatch({ control, name: 'hotelStar' });
  const hasMezzanineWatch = useWatch({ control, name: 'hasMezzanine' });
  const { provinces } = useConfigurationContext();
  const nationOptions = [{ label: 'Việt Nam', value: 'vietnam' }];
  const provinceOptions =
    map(provinces, (province) => ({ value: province.id, label: province.name })) ?? [];
  const { data: getListDistrictsOfProvinceData, loading: loadingGetListDistrictsOfProvince } =
    useFlexQuery<GetListDistrictsOfProvinceData, GetListDistrictsOfProvinceVariables>(
      'map',
      GET_LIST_DISTRICTS_OF_PROVINCE,
      {
        variables: {
          provinceId: provinceWatch,
        },
        skip: isEmpty(provinceWatch),
      },
    );
  const { data: getListWardsOfDistrictData, loading: loadingGetListWardsOfDistrict } = useFlexQuery<
    GetListWardsOfDistrictData,
    GetListWardsOfDistrictVariables
  >('map', GET_LIST_WARDS_OF_DISTRICT, {
    variables: {
      districtId: districtWatch,
    },
    skip: isEmpty(districtWatch),
  });
  const { data: getListStreetsOfDistrictData, loading: loadingGetListStreetsOfDistrict } =
    useFlexQuery<GetListStreetsOfDistrictData, GetListStreetsOfDistrictVariables>(
      'map',
      GET_LIST_STREETS_OF_DISTRICT,
      {
        variables: {
          districtId: districtWatch,
        },
        skip: isEmpty(districtWatch),
      },
    );
  const { data: projectsListData, loading: loadingProjectsList } = useFlexQuery<
    ProjectsListData,
    ProjectsListVariables
  >('main', PROJECTS_LIST, {
    variables: {
      filters: {
        province: provinceWatch,
        district: districtWatch,
      },
      limit: 10000,
    },
    skip: isEmpty(provinceWatch) || isEmpty(districtWatch),
  });
  const districtOptions = map(
    getListDistrictsOfProvinceData?.getListDistrictsOfProvince?.districts,
    (district) => ({
      value: district.id,
      label: district.name,
    }),
  );
  const wardOptions = map(getListWardsOfDistrictData?.getListWardsOfDistrict?.wards, (ward) => ({
    value: ward.id,
    label: ward.name,
  }));
  const streetOptions = map(
    getListStreetsOfDistrictData?.getListStreetsOfDistrict?.streets,
    (street) => ({
      value: street.id,
      label: street.name,
    }),
  );
  const hasAlleyOptions = map([true, false], (hasAlley) => ({
    label: (translation.major.hasAlley as any)[toString(hasAlley)],
    value: toString(hasAlley),
  }));
  const alleyTurnCountOptions = map(range(1, 11, 1), (alleyCount) => ({
    value: toString(alleyCount),
    label: toString(alleyCount),
  }));
  const { projectsList } = projectsListData ?? {};
  const { items: projects } = projectsList ?? {};
  const projectOptions = map(projects, (project) => ({
    value: project._id,
    label: project.title,
  }));
  const countOptions = map(range(0, 5, 1), (bedRoomCount) => ({
    value: toString(bedRoomCount),
    label: toString(bedRoomCount),
  }));
  const directionOptions = map(major?.common?.directions, (direction) => ({
    label: (translation.major.direction as any)[direction.key as string],
    value: direction.key as string,
  }));
  const warehouseTypeOptions = map(major?.common?.warehouseType, (warehouseType) => ({
    label: (translation.major.warehouseType as any)[warehouseType.key as string],
    value: warehouseType.key as string,
  }));
  const buildingTypeOptions = map(major?.filter?.building?.buildingType, (buildingType) => ({
    label: (translation.major.buildingType as any)[buildingType.key as string],
    value: buildingType.key as string,
  }));
  const isNewConstructionOptions = [
    {
      value: 'true',
      label: 'Xây mới',
    },
    {
      value: 'false',
      label: 'Xây cũ',
    },
  ];
  const hotelStarOptions = [
    {
      value: '1',
      label: '1 sao',
    },
    {
      value: '2',
      label: '2 sao',
    },
    {
      value: '3',
      label: '3 sao',
    },
    {
      value: '4',
      label: '4 sao',
    },
    {
      value: '5',
      label: '5 sao',
    },
  ];
  const mezzanineOptions = [
    {
      value: 'true',
      label: 'Có',
    },
    {
      value: 'false',
      label: 'Không',
    },
  ];
  const priceText = !isEmpty(priceWatch)
    ? convertNumberToVietnameseCurrencyLongString(toNumber(priceWatch))
    : null;
  const hasHasAlley = isEqual(hasAlleyWatch, 'true');
  const hasProject = includes(hasProjectTypes, typeWatch);
  const hasBlock = includes(hasBlockTypes, typeWatch);
  const hasBedroomCount = !includes(hasNoBedroomCountTypes, typeWatch);
  const hasToiletCount = !includes(hasNoToiletCountTypes, typeWatch);
  const hasFloorCount = !includes(hasNoFloorCountTypes, typeWatch);
  const hasOnFloor = includes(hasOnFloorTypes, typeWatch);
  const hasClearanceArea = includes(hasClearanceAreaTypes, typeWatch);
  const hasBuildingType = includes(hasBuildingTypeTypes, typeWatch);
  const hasWarehouseType = includes(hasWarehouseTypeTypes, typeWatch);
  const hasIndustryTrade = includes(hasIndustryTradeTypes, typeWatch);
  const hasHotelStar = includes(hasHotelStarTypes, typeWatch);
  const hasHasMezzanine = includes(hasHasMezzanineTypes, typeWatch);
  const countField = size(
    filter(
      [
        true,
        !isEmpty(provinceWatch),
        !isEmpty(districtWatch),
        !isNil(wardWatch),
        !isNil(streetWatch),
        !isEmpty(houseNumberWatch),
        !isEmpty(hasAlleyWatch),
        hasHasAlley ? !isEmpty(alleyTurnCountWatch) : false,
        hasHasAlley ? !isEmpty(frontHomeAlleyWidthWatch) : false,
        hasProject ? !isNil(projectWatch) : false,
        hasBlock ? !isEmpty(blockWatch) : false,
        !isEmpty(widthWatch),
        !isEmpty(lengthWatch),
        !isEmpty(rearWatch),
        !isEmpty(areaWatch),
        !isEmpty(priceWatch),
        hasBedroomCount ? !isNil(bedroomCountWatch) : false,
        hasToiletCount ? !isNil(toiletCountWatch) : false,
        hasFloorCount ? !isNil(floorCountWatch) : false,
        hasOnFloor ? !isNil(onFloorWatch) : false,
        hasClearanceArea ? !isEmpty(clearanceAreaWatch) : false,
        !isEmpty(constructionAreaWatch),
        !isNil(directionWatch),
        !isNil(isNewConstructionWatch),
        hasBuildingType ? !isNil(buildingTypeWatch) : false,
        hasWarehouseType ? !isNil(warehouseTypeWatch) : false,
        hasIndustryTrade ? !isEmpty(industryTradeWatch) : false,
        hasHotelStar ? !isNil(hotelStarWatch) : false,
        hasHasMezzanine ? !isNil(hasMezzanineWatch) : false,
      ],
      Boolean,
    ),
  );
  const totalField =
    15 +
    size(
      filter(
        [
          hasHasAlley,
          hasHasAlley,
          hasProject,
          hasBlock,
          hasBedroomCount,
          hasToiletCount,
          hasFloorCount,
          hasOnFloor,
          hasClearanceArea,
          hasBuildingType,
          hasWarehouseType,
          hasIndustryTrade,
          hasHotelStar,
          hasHasMezzanine,
        ],
        Boolean,
      ),
    );

  useEffect(() => {
    setValue('district', '');
    clearErrors(['district']);
  }, [provinceWatch]);
  useEffect(() => {
    setValue('project', undefined);
    setValue('ward', undefined);
    setValue('street', undefined);
  }, [districtWatch]);
  useEffect(() => {
    setValue('houseNumber', undefined);
  }, [streetWatch]);
  useEffect(() => {
    clearErrors(['alleyTurnCount', 'frontHomeAlleyWidth']);
  }, [hasAlleyWatch]);
  useEffect(() => {
    const project = projects
      ? find(projects, (project) => isEqual((project as Project)._id, projectWatch))
      : null;
    setValue('ward', project?.ward?.id);
    setValue('street', project?.street?.id);
    setValue('houseNumber', undefined);
    setValue('block', undefined);
  }, [projectWatch]);
  useEffect(() => {
    widthWatch && lengthWatch
      ? rearWatch
        ? setValue(
            'area',
            `${round(
              ((toNumber(widthWatch) + toNumber(rearWatch)) / 2) * toNumber(lengthWatch),
              2,
            )}`,
          )
        : setValue('area', `${round(toNumber(widthWatch) * toNumber(lengthWatch), 2)}`)
      : setValue('area', undefined);
  }, [widthWatch, lengthWatch, rearWatch]);

  return (
    <div
      id='detail-information'
      className='flex w-full flex-col space-y-[28px] rounded-[16px] bg-paper p-[24px] shadow-7'
    >
      <div className='flex flex-col space-y-[28px]'>
        <span className='text-[18px] font-[600] leading-[23px]'>{`Chi tiết tin rao (${countField}/${totalField})`}</span>
        <div className='flex flex-col space-y-[28px]'>
          <span className='text-text2'>Địa chỉ</span>
          <div className='flex flex-col space-y-[28px]'>
            <div className='flex space-x-[16px]'>
              <div className='w-full'>
                <FormSelect
                  required
                  disabled
                  placeHolder
                  name='country'
                  label='Quốc gia'
                  options={nationOptions}
                  emptyOptionsText='Không có tuỳ chọn'
                  wrapperClassName='h-[40px] px-[12px] py-[9px]'
                  optionClassName='h-[32px] px-[12px]'
                  popoverClassName='py-[9px]'
                  error={countryError}
                />
              </div>
              <div className='w-full'>
                <FormSelect
                  required
                  name='province'
                  label='Tỉnh/ Thành phố'
                  options={provinceOptions}
                  emptyOptionsText='Không có tuỳ chọn'
                  wrapperClassName='h-[40px] px-[12px] py-[9px]'
                  optionClassName='h-[32px] px-[12px]'
                  popoverClassName='py-[9px]'
                  error={provinceError}
                />
              </div>
            </div>
            <div className='flex space-x-[16px]'>
              <div className='w-full'>
                <FormSelect
                  required
                  name='district'
                  label='Quận/ Huyện'
                  disabled={isEmpty(provinceWatch) || loadingGetListDistrictsOfProvince}
                  options={districtOptions}
                  emptyOptionsText='Không có tuỳ chọn'
                  wrapperClassName='h-[40px] px-[12px] py-[9px]'
                  optionClassName='h-[32px] px-[12px]'
                  popoverClassName='py-[9px]'
                  error={districtError}
                />
              </div>
              <div className='w-full'>
                <FormSelect
                  name='ward'
                  label='Phường/ Xã'
                  options={wardOptions}
                  disabled={
                    isEmpty(districtWatch) || !isNil(projectWatch) || loadingGetListWardsOfDistrict
                  }
                  emptyOptionsText='Không có tuỳ chọn'
                  wrapperClassName='h-[40px] px-[12px] py-[9px]'
                  optionClassName='h-[32px] px-[12px]'
                  popoverClassName='py-[9px]'
                />
              </div>
            </div>
            <div className='flex space-x-[16px]'>
              <div className='w-full'>
                <FormSelect
                  name='street'
                  label='Đường'
                  options={streetOptions}
                  disabled={
                    isEmpty(districtWatch) ||
                    !isNil(projectWatch) ||
                    loadingGetListStreetsOfDistrict
                  }
                  emptyOptionsText='Không có tuỳ chọn'
                  wrapperClassName='h-[40px] px-[12px] py-[9px]'
                  optionClassName='h-[32px] px-[12px]'
                  popoverClassName='py-[9px]'
                />
              </div>
              <div className='w-full'>
                <FormTextInput
                  name='houseNumber'
                  label='Số nhà'
                  maxLength={200}
                  disabled={isEmpty(streetWatch) || !isNil(projectWatch)}
                  wrapperClassName='h-[40px] px-[12px] py-[11px]'
                />
              </div>
            </div>
            <FormRadio
              name='hasAlley'
              options={hasAlleyOptions}
              label='Vị trí'
              labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
              optionClassName='flex items-center space-x-[10px]'
              groupClassName='flex items-center space-x-[28px]'
              wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
            />
            {hasHasAlley && (
              <div className='flex space-x-[16px]'>
                <div className='w-full'>
                  <FormSelect
                    name='alleyTurnCount'
                    required
                    label='Số lần quẹo hẻm'
                    options={alleyTurnCountOptions}
                    error={alleyTurnCountError}
                    emptyOptionsText='Không có tuỳ chọn'
                    wrapperClassName='h-[40px] px-[12px] py-[9px]'
                    optionClassName='h-[32px] px-[12px]'
                    popoverClassName='py-[9px]'
                  />
                </div>
                <div className='w-full'>
                  <FormNumberInput
                    name='frontHomeAlleyWidth'
                    required
                    maxLength={3}
                    label='Độ rộng hẻm (m)'
                    error={frontHomeAlleyWidthError}
                    wrapperClassName='h-[40px] px-[12px] py-[11px]'
                  />
                </div>
              </div>
            )}
            {hasProject && (
              <FormSelect
                name='project'
                disabled={isEmpty(provinceWatch) || isEmpty(districtWatch) || loadingProjectsList}
                label='Tên dự án'
                options={projectOptions}
                emptyOptionsText='Không có tuỳ chọn'
                error={projecError}
                wrapperClassName='h-[40px] px-[12px] py-[9px]'
                optionClassName='h-[32px] px-[12px]'
                popoverClassName='py-[9px]'
              />
            )}
            {hasBlock && (
              <FormTextInput
                name='block'
                label='Thuộc Block/ Khu'
                disabled={isEmpty(projectWatch)}
                maxLength={118}
                error={blockError}
                wrapperClassName='h-[40px] px-[12px] py-[11px]'
              />
            )}
            <div className='flex space-x-[16px]'>
              <div className='w-full'>
                <FormNumberInput
                  name='width'
                  required
                  maxLength={10}
                  label='Ngang'
                  error={widthError}
                  wrapperClassName='h-[40px] px-[12px] py-[11px]'
                />
              </div>
              <div className='w-full'>
                <FormNumberInput
                  name='length'
                  required
                  maxLength={11}
                  label='Dài'
                  error={lengthError}
                  wrapperClassName='h-[40px] px-[12px] py-[11px]'
                />
              </div>
            </div>
            <FormNumberInput
              name='rear'
              maxLength={10}
              label='Mặt hậu'
              wrapperClassName='h-[40px] px-[12px] py-[11px]'
            />
            <FormNumberInput
              disabled
              name='area'
              label={`Diện tích ${
                includes(hasDiffAreaLabelTypes, typeWatch) ? ' tim tường' : ''
              } (m\u00B2)`}
              wrapperClassName='h-[40px] px-[12px] py-[11px]'
            />
            <div className='flex flex-col space-y-[8px]'>
              <FormNumberInput
                name='price'
                maxLength={25}
                label='Giá'
                wrapperClassName='h-[40px] px-[12px] py-[11px]'
              />
              {priceText && <span className='font-[600] text-alert'>{priceText}</span>}
            </div>
            {hasBedroomCount && (
              <div className='flex flex-wrap space-x-[16px] xl:space-x-[24px]'>
                <FormRadio
                  name='bedRoomCount'
                  options={countOptions}
                  label='Số phòng ngủ'
                  labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
                  optionClassName='flex items-center space-x-[10px]'
                  groupClassName='flex items-center space-x-[16px] xl:space-x-[24px]'
                  wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
                />
                <FormNumberInput
                  name='bedRoomCount'
                  maxLength={3}
                  placeHolder
                  label='Khác'
                  wrapperClassName={`h-[40px] px-[12px] py-[11px] w-[68px] ${
                    gt(bedroomCountWatch, size(countOptions) - 1) ? 'bg-primary text-paper' : ''
                  }`}
                />
              </div>
            )}
            {hasToiletCount && (
              <div className='flex flex-wrap space-x-[16px] xl:space-x-[24px]'>
                <FormRadio
                  name='toiletCount'
                  options={countOptions}
                  label='Số nhà vệ sinh'
                  labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
                  optionClassName='flex items-center space-x-[10px]'
                  groupClassName='flex items-center space-x-[16px] xl:space-x-[24px]'
                  wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
                />
                <FormNumberInput
                  name='toiletCount'
                  maxLength={3}
                  placeHolder
                  label='Khác'
                  wrapperClassName={`h-[40px] px-[12px] py-[11px] w-[68px] ${
                    gt(toiletCountWatch, size(countOptions) - 1) ? 'bg-primary text-paper' : ''
                  }`}
                />
              </div>
            )}
            {hasFloorCount && (
              <div className='flex flex-wrap space-x-[16px] xl:space-x-[24px]'>
                <FormRadio
                  required={includes(requiredFloorCountTypes, typeWatch)}
                  name='floorCount'
                  options={countOptions}
                  label='Số tầng'
                  error={floorCountError}
                  labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
                  optionClassName='flex items-center space-x-[10px]'
                  groupClassName='flex items-center space-x-[16px] xl:space-x-[24px]'
                  wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
                />
                <FormNumberInput
                  name='floorCount'
                  maxLength={3}
                  placeHolder
                  label='Khác'
                  wrapperClassName={`h-[40px] px-[12px] py-[11px] w-[68px] ${
                    gt(floorCountWatch, size(countOptions) - 1) ? 'bg-primary text-paper' : ''
                  }`}
                />
              </div>
            )}
            {hasOnFloor && (
              <div className='flex flex-wrap space-x-[16px] xl:space-x-[24px]'>
                <FormRadio
                  name='onFloor'
                  options={countOptions}
                  label='Vị trí tầng'
                  labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
                  optionClassName='flex items-center space-x-[10px]'
                  groupClassName='flex items-center space-x-[16px] xl:space-x-[24px]'
                  wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
                />
                <FormNumberInput
                  name='onFloor'
                  maxLength={3}
                  placeHolder
                  label='Khác'
                  wrapperClassName={`h-[40px] px-[12px] py-[11px] w-[68px] ${
                    gt(onFloorWatch, size(countOptions) - 1) ? 'bg-primary text-paper' : ''
                  }`}
                />
              </div>
            )}
            {hasClearanceArea && (
              <FormNumberInput
                name='clearanceArea'
                maxLength={23}
                label={`Diện tích thông thuỷ (m\u00B2)`}
                wrapperClassName='h-[40px] px-[12px] py-[11px]'
              />
            )}
            <FormNumberInput
              name='constructionArea'
              maxLength={23}
              required={includes(requiredConstructionAreaTypes, typeWatch)}
              label={`Diện tích sàn (m\u00B2)`}
              error={constructionAreaError}
              wrapperClassName='h-[40px] px-[12px] py-[11px]'
            />
            <FormSelect
              name='direction'
              label='Hướng'
              options={directionOptions}
              emptyOptionsText='Không có tuỳ chọn'
              wrapperClassName='h-[40px] px-[12px] py-[9px]'
              optionClassName='h-[40px] px-[12px]'
              popoverClassName='py-[9px]'
            />
            <FormRadio
              name='isNewConstruction'
              options={isNewConstructionOptions}
              label='Hiện trạng'
              labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
              optionClassName='flex items-center space-x-[10px]'
              groupClassName='flex items-center space-x-[28px]'
              wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
            />
            {hasBuildingType && (
              <FormSelect
                name='buildingType'
                label='Loại cao ốc'
                options={buildingTypeOptions}
                emptyOptionsText='Không có tuỳ chọn'
                wrapperClassName='h-[40px] px-[12px] py-[9px]'
                optionClassName='h-[40px] px-[12px]'
                popoverClassName='py-[9px]'
              />
            )}
            {hasWarehouseType && (
              <FormSelect
                name='warehouseType'
                label='Chức năng nhà xưởng'
                options={warehouseTypeOptions}
                emptyOptionsText='Không có tuỳ chọn'
                wrapperClassName='h-[40px] px-[12px] py-[9px]'
                optionClassName='h-[40px] px-[12px]'
                popoverClassName='py-[9px]'
              />
            )}
            {hasIndustryTrade && (
              <FormTextInput
                name='industryTrade'
                label='Ngành hàng'
                maxLength={88}
                wrapperClassName='h-[40px] px-[12px] py-[11px]'
              />
            )}
            {hasHotelStar && (
              <FormSelect
                name='hotelStar'
                label='Số sao'
                options={hotelStarOptions}
                emptyOptionsText='Không có tuỳ chọn'
                wrapperClassName='h-[40px] px-[12px] py-[9px]'
                optionClassName='h-[40px] px-[12px]'
                popoverClassName='py-[9px]'
              />
            )}
            {hasHasMezzanine && (
              <FormRadio
                name='hasMezzanine'
                options={mezzanineOptions}
                label='Có gác hay không'
                labelClassName='text-[14px] leading-[18px] min-w-[120px] xl:min-w-[150px]'
                optionClassName='flex items-center space-x-[10px]'
                groupClassName='flex items-center space-x-[28px]'
                wrapperClassName='flex items-center min-h-[40px] space-x-[24px] xl:space-x-[32px]'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInformation;
