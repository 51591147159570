import { gt, toNumber } from 'lodash';
import moment from 'moment-timezone';
import * as yup from 'yup';

export interface ApartmentValuationForm {
  nation?: 'vietnam';
  province?: string;
  project?: string;
  building?: string;
  floor?: string;
  property?: string;
  area?: string;
  isOneHousing?: boolean;
  valuationDate?: string;
}

export const apartmentValuationFormDefaultValues: ApartmentValuationForm = {
  nation: 'vietnam',
  province: undefined,
  project: undefined,
  building: undefined,
  floor: undefined,
  property: undefined,
  area: undefined,
  isOneHousing: true,
  valuationDate: moment('2022-12').format('YYYY-MM'),
};

export const apartmentValuationFormSchema = yup.object().shape({
  nation: yup.string().required('Vui lòng chọn quốc gia'),
  province: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  project: yup.string().required('Vui lòng chọn khu đô thị'),
  building: yup.string().when('isOneHousing', {
    is: true,
    then: yup.string().required('Vui lòng chọn tòa nhà'),
  }),
  floor: yup.string().when('isOneHousing', {
    is: true,
    then: yup.string().required('Vui lòng chọn tầng'),
  }),
  property: yup.string().when('isOneHousing', {
    is: true,
    then: yup.string().required('Vui lòng chọn mã căn'),
  }),
  area: yup
    .string()
    .required('Vui lòng nhập diện tích')
    .max(16, 'Diện tích không quá 16 chữ số')
    .test('Is positive', 'Vui lòng nhập số lớn hơn 0', (value) => gt(toNumber(value), 0)),
});
