import { NAME_REGEX, PHONE_NUMBER_REGEX, VIETNAMESE_ID_NUMBER_REGEX } from 'globalConstants';
import { GenderEnumType, UserTypeEnum } from 'graphql/main/queries/get-real-estates';
import { isEqual, lt, map, size } from 'lodash';
import * as yup from 'yup';

export interface MyProfileForm {
  fullName?: string;
  type?: UserTypeEnum;
  phones?: { phone?: string }[];
  dateOfBirth?: string;
  email?: string;
  gender?: GenderEnumType;
  idNumber?: string;
  province?: string;
  company?: string;
}

export const myProfileFormDefaultValues: MyProfileForm = {
  fullName: undefined,
  type: undefined,
  phones: undefined,
  dateOfBirth: undefined,
  email: undefined,
  gender: undefined,
  idNumber: undefined,
  province: undefined,
  company: undefined,
};

export const myProfileFormSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Vui lòng nhập họ và tên')
    .matches(NAME_REGEX, 'Vui lòng nhập họ và tên hợp lệ'),
  phones: yup.array().of(
    yup.object().shape({
      phone: yup
        .string()
        .required('Vui lòng nhập số điện thoại')
        .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng')
        .test('unique', 'Số điện thoại bị trùng lặp', (value, context: any) => {
          const array = map(context.options.from?.[1]?.value.phones, (item) => item.phone);
          for (let index = 0; lt(index, size(array)); index++) {
            if (!isEqual(index, context.options.index) && isEqual(array[index], value)) {
              return false;
            }
          }

          return true;
        }),
    }),
  ),
  idNumber: yup.string().matches(VIETNAMESE_ID_NUMBER_REGEX, 'Vui lòng nhập số CMND/CCCD hợp lệ'),
});
