import { gql } from '@apollo/client';
import {
  Boolean,
  DateTime,
  Float,
  Int,
  ObjectID,
  String,
} from '../../main/queries/get-real-estates';
import { Province } from './get-list-provinces';
import { PaginationOf } from 'graphql/main/queries/get-user-saved-real-estate';

export interface Point {
  coordinates?: Float[];
  type?: String;
}
export interface Street {
  createdAt?: DateTime;
  district?: District;
  districtId?: ObjectID;
  id: ObjectID;
  isAdministrativeArea?: Boolean;
  isEntertainmentArea?: Boolean;
  isFoodnDrinkArea?: Boolean;
  isNearSchool?: Boolean;
  isResidentialArea?: Boolean;
  isShoppingArea?: Boolean;
  lat?: Float;
  location?: Point;
  long?: Float;
  name?: String;
  province?: Province;
  shortname?: String;
  updatedAt?: DateTime;
  ward?: Ward;
}
export interface Ward {
  createdAt?: DateTime;
  district?: District;
  districtId?: ObjectID;
  id: ObjectID;
  lat?: Float;
  location?: Point;
  long?: Float;
  name?: String;
  shortname?: String;
  streets?: Street[];
  updatedAt?: DateTime;
}
export interface District {
  createdAt?: DateTime;
  id: ObjectID;
  lat?: Float;
  long?: Float;
  name?: String;
  province?: Province;
  provinceId?: ObjectID;
  shortname?: String;
  streets?: Street[];
  updatedAt?: DateTime;
  wards?: Ward[];
}
export interface ListDistricts extends PaginationOf {
  districts?: District[];
}

export interface GetListDistrictsOfProvinceData {
  getListDistrictsOfProvince?: ListDistricts;
}
export interface GetListDistrictsOfProvinceVariables {
  provinceId: ObjectID;
  name?: String;
  limit?: Int;
  page?: Int;
}

export const GET_LIST_DISTRICTS_OF_PROVINCE = gql`
  query getListDistrictsOfProvince($provinceId: ObjectID!, $name: String, $limit: Int, $page: Int) {
    getListDistrictsOfProvince(provinceId: $provinceId, name: $name, limit: $limit, page: $page) {
      districts {
        id
        name
      }
    }
  }
`;
