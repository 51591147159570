import { Direction, RangeValue } from '../graphql/main/queries/get-real-estates';
import { RealEstateTypeEnum } from '../graphql/main/queries/slugery';

export interface SearchRealEstateOnMap {
  search?: string;
  demand?: 'ForSale' | 'ForRent';
  realEstateType?: RealEstateTypeEnum[];
  price?: RangeValue;
  area?: RangeValue;
  hasAlley?: boolean;
  direction?: Direction[];
  width?: RangeValue;
  length?: RangeValue;
  floorCount?: RangeValue;
  bedroomCount?: RangeValue;
  toiletCount?: RangeValue;
}

export const searchRealEstateOnMapDefaultValue: SearchRealEstateOnMap = {
  search: undefined,
  demand: 'ForSale',
  realEstateType: undefined,
  price: undefined,
  area: undefined,
  hasAlley: undefined,
  direction: undefined,
  width: undefined,
  length: undefined,
  floorCount: undefined,
  bedroomCount: undefined,
  toiletCount: undefined,
};
