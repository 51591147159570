export interface SortForm {
  sort?: string;
}

export const sortFormDefaultValues: SortForm = {
  sort: undefined,
};

export const sortOptions = [
  { label: 'Mới nhất', value: '-createdAt' },
  { label: 'Cũ nhất', value: 'createdAt' },
  { label: 'Giá thấp nhất', value: 'price' },
  { label: 'Giá cao nhất', value: '-price' },
];
