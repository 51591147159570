import { CommonAddress, Direction, RangeValue } from '../graphql/main/queries/get-real-estates';
import { RealEstateTypeEnum } from '../graphql/main/queries/slugery';

export interface SearchForm1 {
  search?: string;
  demand?: string;
  realEstateTypes?: RealEstateTypeEnum[];
  province?: CommonAddress;
  district?: CommonAddress;
  ward?: CommonAddress;
  street?: CommonAddress;
  area?: RangeValue;
  price?: RangeValue;
  hasAlley?: string;
  width?: RangeValue;
  length?: RangeValue;
  direction?: Direction;
  bedroomCount?: RangeValue;
  toiletCount?: RangeValue;
  floorCount?: RangeValue;
  postType?: string;
}

export interface SearchForm2 {
  search?: string;
  demand?: 'sell' | 'rent';
  realEstateTypes?: RealEstateTypeEnum[];
  province?: string;
  district?: string;
  ward?: string;
  street?: string;
  price?: string;
  area?: string;
}

export const searchFormDefaultValues1: SearchForm1 = {
  search: undefined,
  demand: 'sell',
  realEstateTypes: undefined,
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  price: undefined,
  area: undefined,
  hasAlley: undefined,
  width: undefined,
  length: undefined,
  direction: undefined,
  bedroomCount: undefined,
  toiletCount: undefined,
  floorCount: undefined,
  postType: undefined,
};

export const searchFormDefaultValues2: SearchForm2 = {
  search: undefined,
  demand: 'sell',
  realEstateTypes: undefined,
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  price: undefined,
  area: undefined,
};
