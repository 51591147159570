import {
  requiredConstructionAreaTypes,
  requiredFloorCountTypes,
} from 'components/desktop/post-real-estate/main-section/main-section-left/detail-information';
import { NAME_REGEX, PHONE_NUMBER_REGEX, YOUTUBE_LINK_REGEX } from 'globalConstants';
import { ViewEnumType } from 'graphql/main/mutations/create-real-estate';
import { DirectionEnumType } from 'graphql/main/queries/get-real-estates';
import {
  EquipmentsAndServicesEnumType,
  FixedUtilitiesEnumType,
  LocalUtilitiesEnumType,
  UtilitiesEnumType,
  WarehouseEnumType,
} from 'graphql/main/queries/real-estate-on-map-v3';
import { BuildingType, RealEstateTypeEnumType } from 'graphql/main/queries/slugery';
import { includes, isEqual, lt, map, size } from 'lodash';
import * as yup from 'yup';

export interface PostAndUpdateRealEstateForm {
  isForSell: boolean;
  type: RealEstateTypeEnumType;
  postTitle: string;
  postContent: string;
  privateNote?: string;
  country: 'vietnam';
  province: string;
  district: string;
  ward?: string;
  street?: string;
  houseNumber?: string;
  hasAlley: string;
  alleyTurnCount?: string;
  frontHomeAlleyWidth?: string;
  project?: string;
  block?: string;
  width?: string;
  length?: string;
  rear?: string;
  area?: string;
  price?: string;
  bedRoomCount?: string;
  toiletCount?: string;
  floorCount?: string;
  onFloor?: string;
  clearanceArea?: string;
  constructionArea?: string;
  isNewConstruction?: string;
  buildingType?: BuildingType;
  warehouseType?: WarehouseEnumType;
  industryTrade?: string;
  hotelStar?: string;
  hasMezzanine?: string;
  legals?: string[];
  fixedUtilities?: FixedUtilitiesEnumType[];
  utilities?: UtilitiesEnumType[];
  localUtilities?: LocalUtilitiesEnumType[];
  equipmentsAndServices?: EquipmentsAndServicesEnumType[];
  villaView?: ViewEnumType[];
  mediaUrls?: string[];
  direction?: DirectionEnumType;
  captchaId: string;
  captchaCode: string;
  contactName: string;
  contactPhones: { contactPhone: string }[];
  contactEmail?: string;
  vr360Url?: string;
  vr360Urls?: string[];
  youtubeUrl?: string;
}

export const postAndUpdateRealEstateFormDefaultValues: PostAndUpdateRealEstateForm = {
  isForSell: true,
  type: RealEstateTypeEnumType.townhouse,
  postTitle: '',
  postContent: '',
  privateNote: undefined,
  country: 'vietnam',
  province: '',
  district: '',
  ward: undefined,
  street: undefined,
  houseNumber: undefined,
  hasAlley: 'false',
  alleyTurnCount: undefined,
  frontHomeAlleyWidth: undefined,
  project: undefined,
  block: undefined,
  width: undefined,
  length: undefined,
  rear: undefined,
  area: undefined,
  price: undefined,
  bedRoomCount: '1',
  toiletCount: '1',
  floorCount: '0',
  onFloor: '1',
  clearanceArea: undefined,
  constructionArea: undefined,
  isNewConstruction: 'true',
  buildingType: undefined,
  warehouseType: undefined,
  industryTrade: undefined,
  hotelStar: '1',
  hasMezzanine: 'false',
  legals: undefined,
  fixedUtilities: undefined,
  utilities: undefined,
  localUtilities: undefined,
  equipmentsAndServices: undefined,
  villaView: undefined,
  mediaUrls: undefined,
  direction: undefined,
  captchaId: '',
  captchaCode: '',
  contactName: '',
  contactPhones: [],
  contactEmail: undefined,
  vr360Url: undefined,
  vr360Urls: undefined,
  youtubeUrl: undefined,
};

export const postAndUpdateRealEstateFormSchema = yup.object().shape({
  isForSell: yup.boolean().required('Vui lòng chọn nhu cầu'),
  type: yup.string().required('Vui lòng chọn loại bất động sản'),
  postTitle: yup
    .string()
    .required('Vui lòng nhập tiêu đề tin đăng')
    .min(28, 'Tiêu đề tin rao phải có ít nhất 28 ký tự'),
  postContent: yup
    .string()
    .required('Vui lòng nhập nội dung tin đăng')
    .min(28, 'Nội dung tin rao phải có ít nhất 28 ký tự'),
  country: yup.string().required('Vui lòng chọn quốc gia'),
  province: yup.string().required('Vui lòng chọn tỉnh/ thành phố'),
  district: yup.string().required('Vui lòng chọn quận/ huyện'),
  alleyTurnCount: yup.string().when('hasAlley', {
    is: 'true',
    then: yup.string().required('Vui lòng chọn số lần quẹo hẻm'),
  }),
  frontHomeAlleyWidth: yup.string().when('hasAlley', {
    is: 'true',
    then: yup.string().required('Vui lòng nhập độ rộng hẻm'),
  }),
  width: yup.string().required('Vui lòng nhập chiều ngang'),
  length: yup.string().required('Vui lòng nhập chiều dài'),
  floorCount: yup.string().when('type', {
    is: (value: string) => includes(requiredFloorCountTypes, value),
    then: yup.string().required('Vui lòng chọn hoặc nhập số tầng'),
  }),
  constructionArea: yup.string().when('type', {
    is: (value: string) => includes(requiredConstructionAreaTypes, value),
    then: yup.string().required('Vui lòng nhập diện tích sàn'),
  }),
  contactName: yup
    .string()
    .required('Vui lòng nhập tên liên hệ')
    .matches(NAME_REGEX, 'Vui lòng nhập tên liên hệ hợp lệ'),
  contactPhones: yup.array().of(
    yup.object().shape({
      contactPhone: yup
        .string()
        .required('Vui lòng nhập số điện thoại')
        .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng')
        .test('unique', 'Số điện thoại bị trùng lặp', (value, context: any) => {
          const array = map(context.options.from?.[1]?.value.contactPhones, (item) => item.phone);
          for (let index = 0; lt(index, size(array)); index++) {
            if (!isEqual(index, context.options.index) && isEqual(array[index], value)) {
              return false;
            }
          }
          return true;
        }),
    }),
  ),
  contactEmail: yup.string().email('Vui lòng nhập email đúng định dạng'),
  captchaCode: yup.string().required('Vui lòng nhập mã captcha'),
  vr360Url: yup.string().url('Vui lòng nhập một đường dẫn hợp lệ'),
  youtubeUrl: yup
    .string()
    .matches(YOUTUBE_LINK_REGEX, 'Vui lòng nhập một đường dẫn YouTube hợp lệ'),
});
