import { forEach, isEqual, map } from 'lodash';
import { ChangeEventHandler } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  options?: Option[];
  error?: { message?: string };
  labelClassName?: string;
  optionClassName?: string;
  groupClassName?: string;
  wrapperClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}
interface Option {
  value?: string;
  label?: string;
}

const FormRadio = ({
  name,
  required,
  label,
  options,
  error,
  labelClassName,
  optionClassName,
  groupClassName,
  wrapperClassName,
  onChange,
}: Props) => {
  const form = useFormContext();
  const valueRegister = form.register(name);
  const valueWatch = useWatch({ control: form.control, name });
  const handleOnSelectRadio = (option: Option) => {
    const divElement = document.getElementById(`${name}-form-radio`);
    if (divElement) {
      forEach((divElement as HTMLDivElement).children, (inputElement) => {
        const changeEvent = new Event('change', { bubbles: true });
        if (isEqual((inputElement as HTMLInputElement).value, option.value)) {
          (inputElement as HTMLInputElement).checked = true;
          inputElement.dispatchEvent(changeEvent);
          form.setValue(name, option.value, { shouldValidate: true });
          onChange?.(changeEvent as any);
        }
      });
    }
  };

  return (
    <div id={`form-radio-wrapper-${name}`} className='flex select-none flex-col'>
      <div id={`${name}-form-radio`} className='hidden'>
        {map(options, (option, optionIndex) => (
          <input
            {...valueRegister}
            id={`${name}-form-radio-${optionIndex}`}
            key={`${option.value}-${optionIndex}`}
            type='radio'
            defaultChecked={isEqual(valueWatch, option.value)}
            value={option.value}
          />
        ))}
      </div>
      <div className={wrapperClassName ?? ''}>
        {label && (
          <label
            className={`pointer-events-none text-[12px] leading-[15px] ${labelClassName ?? ''}`}
          >
            <span className='text-text2'>{label}</span>{' '}
            <span className='text-alert'>{required && '*'}</span>
          </label>
        )}
        <div className={groupClassName ?? ''}>
          {map(options, (option, optionIndex) => (
            <div
              key={`${option.value}-${optionIndex}`}
              className={`flex h-[24px] cursor-pointer items-center space-x-[10px] ${optionClassName}`}
              onClick={() => {
                handleOnSelectRadio(option);
              }}
            >
              <div
                className={`min-h-[20px] min-w-[20px] rounded-full border ${
                  error
                    ? 'border-alert'
                    : isEqual(valueWatch, option.value)
                    ? 'border-primary'
                    : 'border-stroke'
                } ${
                  isEqual(valueWatch, option.value)
                    ? 'relative before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                    : ''
                }`}
              />
              <span className={`${isEqual(valueWatch, option.value) ? 'font-[600]' : ''}`}>
                {option.label}
              </span>
            </div>
          ))}
        </div>
      </div>
      {error && (
        <div className='mt-[3px] flex w-full py-[3px] px-[12px]'>
          <span className='text-[12px] leading-[15px] text-alert'>{error?.message}</span>
        </div>
      )}
    </div>
  );
};

export default FormRadio;
