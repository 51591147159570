import { gql } from '@apollo/client';
import { Street } from './get-list-districts-of-province';
import { Boolean, Int, ObjectID, String } from '../../main/queries/get-real-estates';
import { PaginationOf } from 'graphql/main/queries/get-user-saved-real-estate';

export interface ListStreets extends PaginationOf {
  streets?: Street[];
}

export interface GetListStreetsOfDistrictData {
  getListStreetsOfDistrict?: ListStreets;
}
export interface GetListStreetsOfDistrictVariables {
  districtId: ObjectID;
  name?: String;
  limit?: Int;
  page?: Int;
}

export const GET_LIST_STREETS_OF_DISTRICT = gql`
  query getListStreetsOfDistrict($districtId: ObjectID!, $name: String, $limit: Int, $page: Int) {
    getListStreetsOfDistrict(districtId: $districtId, name: $name, limit: $limit, page: $page) {
      streets {
        id
        name
      }
    }
  }
`;
