import { filter, find, includes, isEmpty, isEqual, map, toLower, toString } from 'lodash';
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { useOutsideClick } from 'hooks';
import { removeVietnameseAccents } from 'utils';

interface Props {
  name: string;
  disabled?: boolean;
  required?: boolean;
  placeHolder?: boolean;
  label?: string;
  options?: Option[];
  error?: { message?: string };
  emptyOptionsText?: string;
  wrapperClassName?: string;
  popoverClassName?: string;
  labelClassName?: string;
  textFieldClassName?: string;
  optionClassName?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  onClear?: () => void;
}
interface Option {
  value?: string;
  label?: string;
}

const isBrowser = !isEqual(typeof window, 'undefined');

const FormSelect = ({
  name,
  disabled,
  required,
  placeHolder,
  label,
  options,
  error,
  emptyOptionsText,
  wrapperClassName,
  popoverClassName,
  labelClassName,
  textFieldClassName,
  optionClassName,
  onChange,
  onBlur,
  onClear,
}: Props) => {
  const form = useFormContext();
  const valueRegister = form.register(name);
  const valueWatch = useWatch({ control: form.control, name });
  const [privateOptions, setPrivateOptions] = useState(options);
  const [isOpen, setOpen] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const selectElement = isBrowser ? document.getElementById(`${name}-form-select`) : null;

  const handleOnClickOutside = () => {
    if (!disabled) {
      setOpen(!isOpen);
    }
  };
  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivateOptions(
      isEmpty(event.target.value)
        ? options
        : filter(options, (option) =>
            includes(
              removeVietnameseAccents(toString(option.label)),
              removeVietnameseAccents(event.target.value),
            ),
          ),
    );
  };
  const handleOnSelectOption = (option: Option) => {
    if (selectElement) {
      (selectElement as HTMLSelectElement).value = toString(option.value);
      selectElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
    form.setValue(name, option.value, { shouldValidate: true });
  };
  const handleOnClearSelect = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (selectElement) {
      (selectElement as HTMLSelectElement).value = '';
      selectElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
    form.setValue(name, undefined);
    onClear?.();
  };

  useOutsideClick(selectContainerRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    const inputElement = document.getElementById(`${name}-form-select-input`);
    if (inputElement) {
      (inputElement as HTMLInputElement).focus();
      setPrivateOptions(options);
    }
  }, [isOpen]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setPrivateOptions(options);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [valueWatch]);

  return (
    <div id={`form-select-wrapper-${name}`}>
      <select
        {...valueRegister}
        id={`${name}-form-select`}
        className='absolute left-[-9999px]'
        onChange={onChange}
        onBlur={onBlur}
      >
        <option value='' />
        {map(options, (option, optionIndex) => (
          <option key={`${option.value}-${optionIndex}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div
        ref={selectContainerRef}
        className={`flex select-none flex-col space-y-[3px] ${disabled ? '' : 'cursor-text'}`}
        onClick={handleOnClickOutside}
      >
        <div className={disabled ? 'pointer-events-none' : ''}>
          <fieldset
            className={`group relative flex items-center rounded-[8px] border ${
              error ? 'border-alert' : isOpen ? 'border-primary' : 'border-stroke'
            } ${wrapperClassName ?? ''} ${disabled ? 'bg-disabled' : ''}`}
          >
            {isOpen || valueWatch ? (
              !placeHolder && (
                <legend className='pointer-events-none mx-[2px] px-[4px] leading-[0]'>
                  <label className='text-[12px]'>
                    <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
                    {required && <span className='text-alert'>*</span>}
                  </label>
                </legend>
              )
            ) : (
              <label className='pointer-events-none absolute top-1/2 -translate-y-1/2'>
                <span className={`text-text2 ${labelClassName ?? ''}`}>{label}</span>{' '}
                {required && <span className='text-alert'>*</span>}
              </label>
            )}
            <div className='flex w-full items-center justify-between space-x-[8px]'>
              <div className='flex w-full items-center'>
                {isOpen ? (
                  <input
                    id={`${name}-form-select-input`}
                    type='text'
                    autoComplete='off'
                    ref={inputRef}
                    defaultValue={
                      find(options, (option) => isEqual(option.value, valueWatch))?.label
                    }
                    className='w-full bg-transparent'
                    onChange={handleOnChangeInput}
                  />
                ) : (
                  <span
                    className={`pointer-events-none w-full line-clamp-1 ${
                      textFieldClassName ?? ''
                    }`}
                  >
                    {find(options, (option) => isEqual(option.value, valueWatch))?.label}
                  </span>
                )}
                {!isOpen && valueWatch && (
                  <button
                    type='button'
                    className='ml-[8px] hidden group-hover:flex'
                    onClick={handleOnClearSelect}
                  >
                    <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                  </button>
                )}
              </div>
              <div className='broder-stroke h-full border-l pl-[8px]'>
                <MdOutlineArrowDropDown
                  className={`min-h-[24px] min-w-[24px] text-text2 transition duration-[200ms] ease-in-out ${
                    isOpen ? 'rotate-180' : ''
                  }`}
                />
              </div>
            </div>
            <div
              className={`${
                isOpen
                  ? `visible mt-[12px] opacity-100 ${
                      isEmpty(privateOptions) ? 'pointer-events-none' : 'pointer-events-auto'
                    }`
                  : 'pointer-events-none invisible opacity-0'
              } absolute top-full left-0 right-0 z-[2] max-h-[200px] space-y-[2px] overflow-y-auto rounded-[8px] bg-paper shadow-13 ${
                popoverClassName ?? ''
              }`}
            >
              {isEmpty(privateOptions) ? (
                <div className={`flex cursor-default items-center ${optionClassName ?? ''}`}>
                  <span className='italic text-text2 line-clamp-1'>{emptyOptionsText}</span>
                </div>
              ) : (
                map(privateOptions, (option, optionIndex) => (
                  <div
                    key={`${option.value}-${optionIndex}`}
                    className={`flex cursor-pointer items-center hover:bg-secondary ${
                      isEqual(valueWatch, option.value) ? 'bg-secondary' : ''
                    } ${optionClassName ?? ''}`}
                    onClick={() => {
                      handleOnSelectOption(option);
                    }}
                  >
                    <span className='line-clamp-1' data-value={option.value}>
                      {option.label}
                    </span>
                  </div>
                ))
              )}
            </div>
          </fieldset>
        </div>
        {error && (
          <div className='flex w-full py-[3px] px-[12px] '>
            <span className='text-[12px] leading-[15px] text-alert'>{error?.message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormSelect;
