import { PHONE_NUMBER_REGEX } from 'globalConstants';
import { SendVerificationCodeType } from 'graphql/main/mutations/verify-otp';
import * as yup from 'yup';

export interface SignUpStep1Form {
  phone: string;
  password: string;
  developer?: string;
  type?: SendVerificationCodeType;
  identifier: string;
}
export interface SignUpStep2Form {
  phoneVerifyToken: string;
  signupType?: string;
}
export interface SignUpForm {
  step1?: SignUpStep1Form;
  step2?: SignUpStep2Form;
}

export const signUpStep1FormDefaultValues: SignUpStep1Form = {
  phone: '',
  password: '',
  developer: undefined,
  type: SendVerificationCodeType.signUp,
  identifier: 'identifier',
};
export const signUpStep2FormDefaultValues: SignUpStep2Form = {
  signupType: 'normal',
  phoneVerifyToken: '',
};

export const signUpStep1FormSchema = yup
  .object()
  .shape({
    phone: yup
      .string()
      .required('Vui lòng nhập số điện thoại')
      .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
    password: yup
      .string()
      .required('Vui lòng nhập mật khẩu')
      .min(8, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự')
      .max(32, 'Mật khẩu phải có ít nhất 8 ký tự và không vượt quá 32 ký tự'),
  })
  .required();
